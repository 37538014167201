import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { sendPasswordResetEmail } from "../api/auth";
import { useTranslations } from "../hooks/translations";
import { REQUIRED, EMAIL, MAX_LENGTH } from "../utils/validators";
import SEO from "../components/seo";
import Form from "../components/form";
import FormInput from "../components/form-input";
import Button from "../components/button";
import StatusModal from "../components/status-modal";

export const query = graphql`
  query ForgotPasswordQuery($locale: String!) {
    translations(locale: $locale) {
      pages {
        forgotPassword
      }
      form
      ...LayoutTranslations
    }
  }
`;

const FIELDS = {
  email: {
    value: "",
    required: true,
    validators: [
      {
        validate: REQUIRED,
        message: "emailRequired"
      },
      {
        validate: EMAIL,
        message: "emailFormat"
      },
      {
        validate: MAX_LENGTH(100),
        message: "emailTooLong"
      }
    ]
  }
};

const ForgotPasswordPage = ({ location }) => {
  const t = useTranslations("pages.forgotPassword");

  const [submitting, setSubmitting] = useState(false);

  const [status, setStatus] = useState(null);

  const enteredEmail = (location.state && location.state.email) || "";

  const formFields = useMemo(() => {
    const fieldsCopy = { ...FIELDS };
    fieldsCopy.email.value = enteredEmail;
    return fieldsCopy;
  }, [enteredEmail]);

  const submitForm = useCallback(
    formData => {
      if (submitting) {
        return null;
      }

      setSubmitting(true);

      return sendPasswordResetEmail(formData.email)
        .then(
          () => {
            setStatus({
              ...t.success
            });
            return true;
          },
          () => {
            setStatus({
              error: true,
              ...t.error
            });
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
    [submitting, setSubmitting, setStatus]
  );

  return (
    <section>
      <div className="bp-container">
        <SEO title={t.title} description={t.metaDescription} robots="noindex" />
        <h1>{t.heading}</h1>
        <p className="bp-subheading">{t.description}</p>

        <Form fields={formFields} onSubmit={submitForm} displayGlobalError errorMessages={t.errors}>
          {({ setFieldValue, fields }) => (
            <>
              <FormInput
                id="email"
                label={t.email}
                type="email"
                field={fields.email}
                onChange={setFieldValue}
                autoFocus
              />

              <Button type="submit" className="bp-standard bp-submit-button" busy={submitting}>
                {t.submit}
              </Button>
            </>
          )}
        </Form>
      </div>

      {status ? (
        <StatusModal
          error={status.error}
          heading={status.heading}
          description={status.description}
          onClose={() => setStatus(null)}
        />
      ) : null}
    </section>
  );
};

ForgotPasswordPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      email: PropTypes.string
    })
  }).isRequired
};

export default ForgotPasswordPage;
